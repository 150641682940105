/* Customize your main colors in :root variables */
:root {
  --main-background-color: #25252500;
  --card-background-color: #51576D;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}
html{
  height: 100%;
  width: 100%;
  background: url("img/mintbg.jpg");
  background-size: cover;
  
}

@media only screen and (max-width: 768px){
 html{
  background-position: center;
 }
}

body{
  background-color:var(--main-background-color);
  font-family: 'Montserrat',sans-serif;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

#root{

}

.wallet-adapter-button[disabled]{
  background: #fdfce0 !important;
  color: #000000 !important;
}
.wallet-adapter-button:not([disabled]):hover{
  background-image: none !important;
}


